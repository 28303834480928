<template lang="pug">
div
  b-modal#modal-data-edit(ref="modal" title="Data" @show="resetModal" @hidden="resetModal" @ok="handleOk")
    b-overlay(:show="isSaving" no-wrap="")
    form(ref="form" @submit.stop.prevent="handleSubmit")
      b-row.my-1
        b-col.pt-2(sm="3")
          label  Station code 
        b-col(sm="9")
          b-input-group(size="sm" type="text")
            b-form-input(v-model="data.sst_code")
      b-row.my-1
        b-col.pt-2(sm="3")
          label  Date 
        b-col(sm="9")
          b-input-group(size="sm")
            b-form-input#example-input(v-model="data.date" type="text" placeholder="YYYY-MM-DD" autocomplete="off" disabled="")
            b-input-group-append
              b-form-datepicker(size="sm" v-model="data.date" button-only="" right="" locale="en-US")
      b-row.my-1
        b-col.pt-2(sm="3")
          label  Time 
        b-col(sm="9")
          b-input-group
            b-form-timepicker(size="sm" v-model="data.time" placeholder="Choose a time")
      b-row.my-1
        b-col.pt-2(sm="3")
          label  Salinity 
        b-col(sm="9")
          b-input-group(size="sm" type="text" append="psu")
            b-form-input(v-model="data.salinity")
</template>

<script>
import * as Services from '../services.js';

export default {
  name: 'data-edit-comp',
  data() {
    return {
      mode: null,
      data: {
        id: 0,
        bat_id: '',
        sst_code: '',
        depth: '',
        date: '',
        time: '',
        salinity: '',
      },
      stationCodeState: null,
      dateState: null,
      timeState: null,
      depthState: null,
      isShow: false,
      isSaving: false,
    };
  },
  mounted() {},
  methods: {
    showModal(options) {
      this.mode = options.mode;
      if (options.mode == 'edit') {
        // this.data = options.data;
        this.data.id = options.data.id;
        this.data.bat_id = options.data.bat_id;
        this.data.sst_code = options.data.sst_code;
        this.data.depth = options.data.depth;
        const datetime = options.data.date.split(' ');
        this.data.date = datetime[0];
        this.data.time = datetime[1];
        this.data.salinity = options.data.salinity;
      }

      this.$bvModal.show('modal-data-edit');
    },
    getData(id) {
      if (this.mode == 'edit') {
        this.$store
          .dispatch(Services.GET_TIMEBASE_DATA, {id: id})
          // go to which page after successfully login
          .then(res => {
            this.data = res.data[0];
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    getParamList() {
      //Get Parameter List
      this.$store
        .dispatch(Services.GET_PARAMETER_LIST)
        // go to which page after successfully login
        .then(res => {
          if (res.status) {
            this.params = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    save() {
      this.isSaving = true;
      this.$store
        .dispatch(Services.SAVE_TIMEBASE_DATA, this.data)
        // go to which page after successfully login
        .then(res => {
          if (res.status) {
            // this.data = res.data[0];
            this.isSaving = false;
            this.$bvToast.toast('Data saved.', {
              title: 'Successfully',
              variant: 'success',
              solid: true,
            });
            this.$parent.getDataList();
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('modal-data-edit');
            });
          } else {
            // this.data = res.data[0];
            this.isSaving = false;
            this.$bvToast.toast(res.message, {
              title: 'Error!',
              variant: 'danger',
              solid: true,
            });
          }
        })
        .catch(error => {
          console.log(error);
          this.isSaving = false;
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.stationCodeState = valid;
      return valid;
    },
    resetModal() {},
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      //Save Data
      this.save();
    },
  },
};
</script>

<style></style>
