<template lang="pug">
div
  b-modal#modal-prevent-closing(ref="modal" title="Upload File" @show="resetModal" @hidden="resetModal" @ok="handleOk")
    b-overlay(:show="isSaving" no-wrap="")
    form(ref="form" @submit.stop.prevent="handleSubmit")
      b-form-group#fieldset-1.pt-3(description="" label="" label-for="input-1")
        b-form-file(accept="text/csv" v-model="file" placeholder="กรุณาเลือกไฟล์" drop-placeholder="Drop file here...")
</template>

<script>
import * as Services from '../services.js';

export default {
  name: 'upload-comp',
  data() {
    return {
      mode: null,
      bat_id: 0,
      file: null,
      isShow: false,
      isSaving: false,
    };
  },
  mounted() {},
  methods: {
    showModal(options) {
      this.bat_id = options.bat_id;
      this.$bvModal.show('modal-prevent-closing');
    },
    save() {
      this.isSaving = true;
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('bat_id', this.bat_id);
      this.$store
        .dispatch(Services.IMPORT_TIMEBASE_DATA, formData)
        // go to which page after successfully login
        .then(res => {
          this.isSaving = false;
          if (res.status) {
            this.$bvToast.toast(
              'Data has been imported.\n\n\n' + res.data.added + ' row(s) of ' + res.data.rows + ' has been imported.',
              {
                title: 'Successfully',
                variant: 'success',
                solid: true,
              },
            );
          } else {
            this.$bvToast.toast(
              'Data has not been imported.\n' + res.data.added + ' row(s) of ' + res.data.rows + ' has been imported.',
              {
                title: 'Failed',
                variant: 'danger',
                noAutoHide: true,
                appendToast: true,
                solid: true,
              },
            );
          }
          if (res.data.station_do_not_exist) {
            this.$bvToast.toast('Unknow station code please check your station code.', {
              title: 'Failed',
              variant: 'danger',
              noAutoHide: true,
              appendToast: true,
              solid: true,
            });
            console.log(typeof res.data.station_do_not_exist);
            for (const station in res.data.station_do_not_exist) {
              this.$bvToast.toast(
                '[' +
                  station +
                  '] - Unknow station code. Data with station code has not been imported. ' +
                  res.data.station_do_not_exist[station] +
                  ' row(s)',
                {
                  title: 'Failed',
                  variant: 'danger',
                  noAutoHide: true,
                  appendToast: true,
                  solid: true,
                },
              );
            }
          }
          this.$parent.getDataList();
        })
        .catch(error => {
          console.log(error);
          this.isSaving = false;
        });
    },

    resetModal() {},
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      //Save Data
      this.save();
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
      });
    },
  },
};
</script>

<style></style>
