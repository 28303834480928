<template lang="pug">
div
  .row
    .col-xxl-8
      .card.card-custom.gutter-b.example.example-compact(v-if="isReady")
        .card-header.pb-0
          .card-title
            h3.card-label
              | {{ $t('salinity.batch_description') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group(description='' :label="`${$t('salinity.batch_name')} (TH)`" label-for='input-1')
            b-form-input#input-1(v-model='post.name_th' trim='' placeholder='untitle' :state="!(post.name_th == '')")
          b-form-group(description='' :label="`${$t('salinity.batch_name')} (EN)`" label-for='input-2')
            b-form-input#input-2(v-model='post.name_en' trim='' placeholder='untitle' :state="!(post.name_en == '')")
          b-form-group#fieldset-1(description='' :label="`${$t('salinity.batch_desc')} (TH)`" label-for='input-3')
            b-form-textarea(v-model='post.description_th' rows='5' max-rows='8')
          b-form-group#fieldset-2(description='' :label="`${$t('salinity.batch_desc')} (EN)`" label-for='input-4')
            b-form-textarea(v-model='post.description_en' rows='5' max-rows='8')
      b-overlay(:show="isSaving" no-wrap="")  
    .col-xxl-4
      .card.card-custom.gutter-b(v-if="isReady")
        .card-header.pb-0
          .card-title
            h3.card-label
              | {{ $t('salinity.publish') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-button.mb-5(variant="primary" block="" @click="savePost") Save
        b-overlay(:show="isSaving" no-wrap="")  

      
      .card.card-custom.gutter-b(v-if='isReady')
        .card-header.pb-0
          .card-title
            h3.card-label {{ $t('GENERAL.attachment') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group#fieldset-1(description='' label='' label-for='input-1')
            b-form-file(
              v-model='attachFiles'
              placeholder='กรุณาเลือกไฟล์'
              drop-placeholder='Drop file here...'
              :file-name-formatter='formatNames'
              multiple=''
              :show='!hasFiles'
            )
            b-button.btn-sm.mt-5(v-if='hasFiles' variant='danger' @click='clearAttachFiles' block='') Clear
            .clearfix.text-center.pt-3(v-if='hasFiles')
              span.center  OR 
              b-button.btn-sm.mt-3(variant='primary' @click='uploadAttachFiles' block='') Upload {{ attachFiles.length }} file(s)
          b-table(small='' responsive='sm' :items='post.attachment' :fields="['filename']" style='overflow: hidden')
            template(#cell()='data')
              | {{ data.value | str_limit(30) }}
              b-button.btn-sm.pt-0.pb-0.pr-2.pl-2.float-right(variant='danger' @click='deleteAttachment(data.item.id)') x
        b-overlay(:show='isSaving || isUploading' no-wrap='')  
 
  .row
    .col-xxl-12
      .card.card-custom.gutter-b.example.example-compact(v-if="isReady")
        .card-header.pb-0
          .card-title
            h3.card-label
              | {{ $t('salinity.batch_data_list') }} - {{ salinity ? salinity.length : 0 }} record(s)
          .card-toolbar
            b-button-toolbar
              b-dropdown.mx-1(variant="primary" size="sm" @click="showAddModal()" right="" split="" text="Import")
                b-dropdown-item(variant="primary" @click="downloadTemplate()") Template
                b-dropdown-item(variant="primary" @click="exportData()" v-if="salinity != null") Export
                b-dropdown-divider(v-if="salinity != null")
                b-dropdown-item(variant="danger" @click="clearData()" v-if="salinity != null") Clear
        .card-body.pt-3.pb-0
          b-table(striped="" hover="" :items="salinity" :fields="fields")
            template(v-slot:cell(id)="data")  {{ ++data.index }} 
            template(v-slot:cell(sst_code)="data")  {{ data.item.sst_code }} - {{ data.item.sst_name }} 
            template(v-slot:cell(action)="data")
              b-button.mr-1(size="sm" variant="primary" @click="editRecord(data.item)") Edit
              b-button(size="sm" variant="danger" @click="deleteRecord(data.item.id)")  Delete 
          b-overlay(:show="isSaving" no-wrap="")  
  uploadComp(ref="uploadModal")
  dataEditComp(ref="dataEditComp")
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import * as Services from '../services.js';

import uploadComp from '../components/Upload_comp.vue';
import dataEditComp from '../components/data_edit_comp.vue';

export default {
  name: 'salinity_batch_add',
  components: {
    uploadComp,
    dataEditComp,
  },
  data() {
    return {
      isReady: false,
      isSaving: false,
      isUploading: false,
      mode: null,
      post: {
        id: 0,
        type: 'salinity',
        name_th: '',
        description_th: '',
        name_en: '',
        description_en: '',
        attachment: [],
      },
      salinity: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'sst_code',
          label: 'Station Code',
          sortable: true,
        },
        {
          key: 'salinity',
          label: 'Salinity',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Date - Time',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Actions',
        },
      ],
      // Attachment
      attachFiles: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Salinity Data', route: '/admin/salinity/batch'}, {title: 'Add'}]);
    this.getData();
  },
  methods: {
    showAddModal() {
      this.$refs.uploadModal.showModal({bat_id: this.post.id});
    },
    clearData() {
      this.deleteConfirm(() => {
        this.isSaving = true;
        this.$store
          .dispatch(Services.CLEAR_TIMEBASE_DATA, {id: this.$route.params.id})
          // go to which page after successfully login
          .then(res => {
            this.getDataList();
            this.isSaving = false;
          })
          .catch(error => {
            // console.log(error);
            this.isSaving = false;
          });
      });
    },
    getData() {
      if (this.$route.name == 'salinity.edit_batch') {
        this.mode = 'edit';
        this.$store
          .dispatch(Services.GET_BATCH_DATA, {id: this.$route.params.id})
          // go to which page after successfully login
          .then(res => {
            this.post = res.data[0];
            this.isReady = true;
          })
          .catch(error => {
            // console.log(error);
          });
        this.getDataList();
      } else {
        this.isReady = true;
        this.mode = 'add';
      }
    },
    getDataList() {
      this.$store
        .dispatch(Services.GET_TIMEBASE_LIST, {bat_id: this.$route.params.id, order: 'id ASC'})
        // go to which page after successfully login
        .then(res => {
          this.salinity = res.data;
          this.isReady = true;
        })
        .catch(error => {
          // console.log(error);
        });
    },
    exportData() {
      this.$store
        .dispatch(Services.GET_EXPORT_TIMEBASE_DATA, this.post.id)
        .then(res => {
          let filename = res.headers['content-disposition'].split('filename="')[1].split('.')[0];
          const blob = new Blob([res.data], {type: 'text/csv'});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          console.log(error);
          this.$bvToast.toast(error, {
            title: 'Export Failed!',
            variant: 'danger',
            noAutoHide: true,
            solid: true,
          });
        });
    },
    downloadTemplate() {
      this.$store
        .dispatch(Services.GET_TIMEBASE_TEMPLATE_FILE)
        .then(res => {
          let filename = res.headers['content-disposition'].split('filename="')[1].split('.')[0];
          const blob = new Blob([res.data], {type: 'text/csv'});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          console.log(error);
        });
    },
    editRecord(data) {
      this.$refs.dataEditComp.showModal({data: data, mode: 'edit'});
    },
    deleteRecord(id) {
      this.deleteConfirm(() => {
        this.$store
          .dispatch(Services.DELETE_TIMEBASE_DATA, {id: id})
          .then(res => {
            if (res.status) {
              this.$bvToast.toast('Data has been deleted', {
                title: 'Successfully',
                variant: 'success',
                solid: true,
              });
              this.getDataList();
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Error!',
                variant: 'danger',
                solid: true,
              });
            }
          })
          .catch(error => {});
      });
    },
    savePost(mode) {
      return new Promise((resolve, reject) => {
        if (this.post.title_th == '' || this.post.title_en == '') {
          this.notify('danger', 'Error!', 'Please complete the form');
          reject('Please complete the form');
        } else {
          this._savePost(mode);
          resolve();
        }
      });
    },
    _savePost(mode) {
      this.isSaving = true;
      this.$store
        .dispatch(Services.SAVE_BATCH_DATA, this.post)
        // go to which page after successfully login
        .then(res => {
          if (res.status) {
            this.post = res.data[0];

            //Upload Attachment
            this.uploadAttachFiles();
          }
          // console.log('Save post');
          this.isSaving = false;
          this.$bvToast.toast('Post Saved.', {
            title: 'Successfully',
            variant: 'success',
            solid: true,
          });
          if (this.$route.name == 'salinity.add_batch') {
            this.$router.push({
              name: 'salinity.edit_batch',
              params: {id: this.post.id},
            });
          }
        })
        .catch(error => {
          this.isSaving = false;
          // console.log(error);
        });
    },
    // Attachment
    uploadAttachFiles() {
      if (this.post.id) {
        //Upload Attachment
        if (this.attachFiles) {
          this.isUploading = true;
          const attachNum = this.attachFiles.length;
          let i = 0;
          this.attachFiles.forEach((item, index) => {
            let formData = new FormData();
            formData.append('file', item);
            formData.append('id', this.post.id);
            formData.append('table', 'salinity');
            this.$store
              .dispatch('UPLOAD_ATTACH', formData)
              .then(res => {
                i++;
                if (res.status) {
                  this.$bvToast.toast('Upload [' + res.data.filename + '] successfully!', {
                    title: 'Successfully',
                    variant: 'success',
                    solid: true,
                  });
                } else {
                  this.$bvToast.toast(res.message, {
                    title: 'Upload Failed!',
                    variant: 'danger',
                    noAutoHide: true,
                    appendToast: true,
                    solid: true,
                  });
                }
                if (i === attachNum) {
                  this.getData();
                }
              })
              .catch(err => {});
          });
          this.attachFiles = [];
          setTimeout(() => {
            this.isUploading = false;
          }, 500);
        }
      } else {
        this.savePost();
      }
    },
    deleteAttachment(id) {
      this.deleteConfirm(() => {
        this.isUploading = true;
        this.$store
          .dispatch('DELETE_ATTACH', {id: id})
          .then(res => {
            this.getData();
            this.isUploading = false;
          })
          .catch(err => {
            this.isUploading = false;
          });
      });
    },
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`;
    },
    clearAttachFiles() {
      this.attachFiles = [];
    },
  },
  computed: {
    hasFiles() {
      return !!this.attachFiles.length;
    },
  },
};
</script>

<style></style>
